import React, { useEffect, useState } from 'react'
import { fetchAllGrade } from '../curriculumDetailsService'
import { useParams } from 'react-router-dom'
import Card from '../../../../../components/Card'
import Table from '../../../../../components/Table/Table'
import { EditLogo } from '../../../../../assets/images/svg'
import AddGradeModal from './AddGradeModal'
import Alert from '../../../../../components/Alerts/Alert'

const GradeList = () => {
    const [grades, setGrades] = useState([])
    const {id} = useParams()
    const [showModal, setShowModal] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMesage] = useState("")
    const [apiCall, setApiCall] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    const [gradeId, setGradeId] = useState(null)


    useEffect(() => {
        const fetchGrades = async() => {
            const data = await fetchAllGrade(id)
            setGrades(data)
        }

        fetchGrades()
    },[apiCall] )


    const gradeStatusList = ['ALL', 'ACTIVE', 'INACTIVE'];

    const columns = [
        {
          name: 'Id',
          selector: row => row.id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
          name: 'Description',
          selector: row => row.description,
          sortable: true,
        },
        {
          name: 'Description',
          selector: row => row.description,
          sortable: true,
        },
        {
          name: 'Status',
          selector: row => row.status,
          sortable: true,
          cell: row => (
            <span className={`status ${row.status.toLowerCase()}`}>
              {row.status}
            </span>
          ),
        },
        {
            name: "",
            button: true,
            cell: (row) => (
              <button
                className="btn"
                onClick={() => {
                    setIsEditable(true)
                    setShowModal(true)
                    setGradeId(row.id)
                }}
              >
                <EditLogo />
              </button>
            ),
          },
      ];


  return (
    <div className='grade-list'>
        {showModal && <AddGradeModal setShowModal={setShowModal} showAlert={showAlert} setShowAlert={setShowAlert} alertMessage={alertMessage} setAlertMesage={setAlertMesage} setApiCall={setApiCall} apiCall = {apiCall} id={id} setIsEditable = {setIsEditable} isEditable = {isEditable} gradeId = {gradeId}/>}
        {showAlert && <Alert message={alertMessage} type="success" onClose={() => setShowAlert(false)} />}
        <Card>
        <Table
            entity="grade-master"
            data={grades}
            columns={columns}
            statusList={gradeStatusList}
            onAddClick = {() => setShowModal(true)}
        />
        </Card>
    </div>
  )
}

export default GradeList