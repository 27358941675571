// src/features/activity/EditActivityModal.js
import React, { useState, useEffect } from 'react';
import { CrossLogo } from '../../../assets/images/svg';
import Button from '../../../components/Button';
// import './editActivityModal.scss';
import "./addActivityModal.scss"
import { updateActivity } from './addActivityModalService';

const EditActivityModal = ({ displayModal, setShowModal, activityData, callApi, setCallApi }) => {
  const [startTime, setStartTime] = useState(activityData.start_time || '');
  const [endTime, setEndTime] = useState(activityData.end_time || '');
  const [status, setStatus] = useState(activityData.status || '');

  useEffect(() => {
    // Populate modal with initial data when opened
    setStartTime(activityData.start_time);
    setEndTime(activityData.end_time);
    setStatus(activityData.status);
  }, [activityData]);

  const handleClose = () => {
    setShowModal(false)
    setCallApi(!callApi)
    
};

  const handleSaveChanges = async () => {
    const response = await updateActivity(startTime, endTime, status, activityData.id)
    alert(response.message)
    
    // onSaveChanges(updatedData);
    handleClose();
  };

  return (
    <div>
      <div className={displayModal ? 'modal show fade d-block' : 'modal fade d-block'} style={{ backgroundColor: 'rgba(0,0,0,.5)' }} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Activity</h5>
              <div className="close-modal" onClick={handleClose}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
            <label for="startTime">Start Time:</label>
<input type="datetime-local" id="startTime" name="startTime" onChange={(e) => {setStartTime(e.target.value)}}/>
<label for="endTime">End Time:</label>
<input type="datetime-local" id="endTime" name="endTime" onChange={(e) => {setEndTime(e.target.value)}}/>
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="scheduled">SCHEDULED</option>
                <option value="inprogress">INPROGRESS</option>
                <option value="completed">COMPLETED</option>
                <option value="submitted">SUBMITTED</option>
                <option value="approved">APPROVED</option>
                <option value="published">PUBLISHED</option>
              </select>
            </div>
            <div className="modal-footer">
              <Button label="Save Changes" backgroundColor="#EA983E" textColor="white" onClick={handleSaveChanges} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditActivityModal;
