import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const staffMasterDetails = async (page, page_size = 10) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getStaff`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      page,
      page_size,
    },
  });

  return response.data;
};

export const createStaffAPI = async (staffData) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/createStaff`,
      staffData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    return error;
  }
};

export const getStaffRoles = async () => {
  try {
    const response = await axios.get(`${DEV_BASE_URL}/api/v1/getRoles`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status) {
      return response.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    return error;
  }
};
