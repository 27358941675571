import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schools: [],
  isSchoolEditable: false,
  isNewEntry: false,
  isEditEntry: false,
  editGradeId: null,
};

const schoolMasterSlice = createSlice({
  name: "schoolMaster",
  initialState,
  reducers: {
    setSchoolMaster: (state, action) => {
      state.schools = action.payload;
    },

    setSchoolEditable: (state, action) => {
      state.isSchoolEditable = action.payload;
    },

    setIsNewEntry: (state, action) => {
      state.isNewEntry = action.payload;
    },
    setIsEditEntry: (state, action) => {
      state.isEditEntry = action.payload;
    },

    setEditGradeId: (state, action) => {
      state.editGradeId = action.payload;
    },
  },
});

export const {
  setSchoolMaster,
  setSchoolEditable,
  setIsNewEntry,
  setIsEditEntry,
  setEditGradeId,
} = schoolMasterSlice.actions;
export default schoolMasterSlice.reducer;
