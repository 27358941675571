import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "./table.scss"; // Custom styles
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { setBreadCrumbHeader } from "../../Layout/SubHeader/breadCrumbSlice";

const Table = ({
  entity,
  data,
  columns,
  statusList,
  onAddClick,
  buttonLabel = "+ Add New",
  clickableRow = true,
  onRowClick = () => {},
  selectableRows = false,
  isServerSidePagination = false,
  setPage = () => {},
  setRowsPerPage = () => {},
  paginationTotalRows,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const handleRowClick = (row) => {
    dispatch(setBreadCrumbHeader(row.name))
    clickableRow && onRowClick(row);
    navigate(`/${entity}/${row.id}`); // Replace `/entity/` with a dynamic path if needed
  };

  const [filterStatus, setFilterStatus] = useState("ALL");
  const filteredData =
    filterStatus === "ALL"
      ? data
      : data.filter(
          (item) => item.status.toLowerCase() === filterStatus.toLowerCase()
        );

  return (
    <div className="table-contents">
      {statusList && (
        <div className="tabs-header">
          <div className="status-tabs">
            {statusList.map((status) => (
              <div
                key={status}
                className={`tab ${filterStatus === status ? "active" : ""}`}
                onClick={() => setFilterStatus(status)}
              >
                {status}{" "}
                {status !== "ALL" &&
                  `(${
                    data.filter(
                      (item) =>
                        item.status.toLowerCase() === status.toLowerCase()
                    ).length
                  })`}
              </div>
            ))}
          </div>
          <div>
            <Button
              label={buttonLabel}
              onClick={onAddClick}
              backgroundColor="#EA983E"
              textColor="white"
            />
          </div>
        </div>
      )}
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        striped
        onRowClicked={(row) => clickableRow && handleRowClick(row)}
        selectableRows={selectableRows}
        paginationServer={isServerSidePagination}
        onChangeRowsPerPage={setRowsPerPage}
        onChangePage={setPage}
        paginationTotalRows={paginationTotalRows}
      />
    </div>
  );
};

export default Table;
