import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../../../components/Card";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import Alert from "../../../../../components/Alerts/Alert";
import {
  addActivity,
  fetchSingleActivity,
  getCurriculumDropdown,
  getGradeDropdown,
  updateActivity,
} from "../../activityMasterService";

import "./activityDetail.scss";
import { setActivityEditable } from "../../activityMasterSlice";

const ActivityDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditable = useSelector(
    (state) => state.activityMaster.isActivityEditable
  );
  const [currentActivity, setCurrentActivity] = useState({}); // Initialize with an empty object
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [curriculumDropdown, setCurriculumDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [curriculumDropdownValue, setCurriculumDropdownValue] = useState("");

  const dispatch = useDispatch();

  const statusOptions = ["active", "inactive"];
  const coursesOptions = ["Friction", "Throw the ball"];

  useEffect(() => {
    const fetchApis = async () => {
    if(id != "add"){
      const act = await fetchSingleActivity(id);
      setCurrentActivity(act);
      const curr = await getCurriculumDropdown(id);
      setCurriculumDropdown(curr);
      setCurriculumDropdownValue(act.curriculum_id);
    }  
    else{
      const curr = await getCurriculumDropdown(id);
      setCurriculumDropdown(curr);
    }
    };

    
      fetchApis();

    return () => {
      // This function is called when the component unmounts
      dispatch(setActivityEditable(false));
    };
  }, [id]);

  const onHandleSave = async () => {
    const data = await addActivity(currentActivity);
    navigate("/activity-master");
  };

  const onHandleEdit = async () => {
    const data = await updateActivity(currentActivity);
    navigate("/activity-master");
  };

  let gradeDrp = async (id) => {
    let grades = await getGradeDropdown(id);
    setGradeDropdown(grades);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    console.log("value: ", e.target.value);
    console.log("name", e.target.name);
    if (name == "curriculum_id") {
      setCurriculumDropdownValue(value);
      gradeDrp(value);
    }
    setCurrentActivity((prev) => ({
      ...prev,
      [name]: files ? files[0] : value, // Dynamically update the correct field
    }));
  };

  return (
    <div className="activity-details-form">
      {showAlert && (
        <Alert
          message={alertMessage}
          type="success"
          onClose={() => setShowAlert(false)}
        />
      )}
      <div className="add-curriculum-form">
        <div className="row mb-2 p-2">
          <div className="col-6">
            <label htmlFor="ActivityId">Id</label>
            <input
              type="text"
              className="form-control"
              id="activityId"
              name="id"
              placeholder="Enter Id"
              value={currentActivity?.id || ""}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              readOnly={true} // Read-only if not editable
            />
          </div>
          <div className="col-6">
            <label htmlFor="activity_master">Name</label>
            <input
              type="text"
              className="form-control"
              id="activityMasterName"
              name="name"
              placeholder="Enter Name"
              value={currentActivity?.name || ""}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              readOnly={!isEditable} // Read-only if not editable
            />
          </div>
        </div>
        <div className="row mb-2 p-2">
          <div className="col-6">
            <label htmlFor="activityCurriculum">Curriculum</label>
            <select
              className="form-control"
              id="curriculum_id"
              name="curriculum_id"
              value={curriculumDropdownValue || ""}
              // value={{label: "Cambridge", value: 1}}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              disabled={!isEditable} // Disable if not editable
            >
              <option value="">Select Curriculum</option>
              {curriculumDropdown.map((curr) => (
                <option key={curr.value} value={curr.value}>
                  {curr.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="activityGrade">Grade</label>
            <select
              className="form-control"
              id="grade_id"
              name="grade_id"
              value={currentActivity?.grade_id || ""}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              disabled={!isEditable} // Disable if not editable
            >
              {/* <option value="">Select Grade</option> */}
              {gradeDropdown.map((grd) => (
                <option key={grd.value} value={grd.value}>
                  {grd.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-2 p-2">
          <div className="col-6">
            <div class="mb-3">
              <label for="formFile" class="form-label">
                Highlight Image
              </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                name="highlight_img" // Ensure the name matches the state property
                onChange={isEditable ? handleInputChange : undefined}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="col-6">
            <label for="formFile" class="form-label">
              Highlight Video
            </label>
            <input
              className="form-control"
              type="file"
              id="highlightVideo"
              name="highlight_vid" // Ensure the name matches the state property
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              disabled={!isEditable} // Disable file upload if not editable
            />
          </div>
        </div>
        <div className="row mb-2 p-2">
          <div className="col-6">
            <label htmlFor="curriculumDescription">Activity Note</label>
            <textarea
              className="form-control"
              id="curriculumDescription"
              name="description"
              placeholder="Enter Description"
              value={currentActivity?.description || ""}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              readOnly={!isEditable} // Read-only if not editable
            />
          </div>
          <div className="col-6">
            <label htmlFor="status">Status</label>
            <select
              className="form-control"
              id="status"
              name="status"
              value={currentActivity?.status || ""}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              disabled={!isEditable} // Disable if not editable
            >
              <option value="">Select Status</option>
              {statusOptions.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-2 p-2">
          <div className="col-6">
            <label htmlFor="courses">Courses</label>
            <select
              className="form-control"
              id="courses"
              name="courses"
              value={currentActivity?.course_id || ""}
              onChange={isEditable ? handleInputChange : undefined} // Only allow changes if isEditable is true
              disabled={!isEditable} // Disable if not editable
            >
              <option value="">Select Courses</option>
              {coursesOptions.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="activity-save-btn">
        {isEditable && (
          <Button
            label="Save"
            backgroundColor="#EA983E"
            textColor="white"
            onClick={id == "add" ? onHandleSave : onHandleEdit} // Add this when save functionality is implemented
          />
        )}
      </div>
    </div>
  );
};

export default ActivityDetail;
