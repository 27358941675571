import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./form.scss";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getSchoolGradeAPI } from "../../features/schools/school/schoolMasterService";
import { getSchoolSectionAPI } from "../../features/schools/student/studentMasterService";

function DynamicFormRenderer({
  formConfig = [],
  handleSubmit = () => {},
  hasCancelBtn = true,
  submitBtnText = "Submit",
  hasButtons = true,
  onCancel = () => {},
}) {
  const initialState = formConfig.reduce((acc, field) => {
    acc[field.name] = field.value || "";
    return acc;
  }, {});

  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [availableGrades, setAvailableGrades] = useState([]);
  const [loadingGrades, setLoadingGrades] = useState(false);
  const [availableSections, setAvailableSections] = useState([]);
  const [loadingSections, setLoadingSections] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const newState = formConfig.reduce((acc, field) => {
      acc[field.name] = field.value || "";
      return acc;
    }, {});
    setFormState(newState);
  }, [formConfig]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "studentSchool") {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setLoadingGrades(true);
      try {
        const grades = await fetchGradesFromAPI(value);
        setAvailableGrades(grades);
      } catch (error) {
        console.error("Error fetching grades:", error);
      } finally {
        setLoadingGrades(false);
      }
    } else if (name === "studentGrade") {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      setLoadingSections(true);
      try {
        const sections = await fetchSectionsFromAPI(value);
        setAvailableSections(sections);
      } catch (error) {
        console.error("Error fetching sections:", error);
      } finally {
        setLoadingSections(false);
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      if (errors[name]) {
        validateField(name, value); // Validate field on change
      }
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
    if (errors[name]) {
      validateField(name, files[0]); // Validate field on file change
    }
  };

  const fetchGradesFromAPI = async (school_id) => {
    const data = await getSchoolGradeAPI(school_id);
    const gradeOptions = data.map((grade) => ({
      value: grade.id,
      label: grade.name,
    }));
    // console.log(gradeOptions);
    return gradeOptions;
  };

  const fetchSectionsFromAPI = async (grade_id) => {
    const data = await getSchoolSectionAPI(grade_id);
    const sectionOptions = data.map((section) => ({
      value: section.id,
      label: section.name,
    }));
    // console.log(sectionOptions);
    return sectionOptions;
  };

  const onCancelClick = () => {
    onCancel();
    setFormState(initialState);
    // navigate(-1);
  };

  const validateField = (name, value) => {
    const field = formConfig.find((f) => f.name === name);
    let errorMsg = "";

    if (field.required && !value) {
      errorMsg = `${field.label || name} is required.`;
    } else if (field.minLength && value.length < field.minLength) {
      errorMsg = `${field.label || name} must be at least ${
        field.minLength
      } characters.`;
    } else if (field.maxLength && value.length > field.maxLength) {
      errorMsg = `${field.label || name} must not exceed ${
        field.maxLength
      } characters.`;
    } else if (field.pattern && !new RegExp(field.pattern).test(value)) {
      errorMsg = `${field.label || name} is not valid.`;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));

    return !errorMsg;
  };

  const validateForm = () => {
    let isValid = true;
    formConfig.forEach((field) => {
      if (!validateField(field.name, formState[field.name])) {
        isValid = false;
      }
    });
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(formState); // Submit form if valid
    } else {
      console.log("Form contains errors");
    }
  };

  const renderField = (field) => {
    const { label, name, type, options, placeholder, readOnly, disabled } =
      field;

    const error = errors[name];

    if (name === "studentGrade") {
      return (
        <div className="form-group mb-2" key={name}>
          <label className="pb-2" htmlFor={name}>
            {label}
          </label>
          <select
            className={`form-select ${error ? "is-invalid" : ""}`}
            id={name}
            name={name}
            value={formState[name]}
            onChange={handleChange}
            disabled={loadingGrades || availableGrades.length === 0}
          >
            <option value="">Select Grade</option>
            {availableGrades.map((grade, index) => (
              <option key={index} value={grade.value}>
                {grade.label}
              </option>
            ))}
          </select>
          {loadingGrades && <small>Loading grades...</small>}
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      );
    }

    if (name === "studentSection") {
      return (
        <div className="form-group mb-2" key={name}>
          <label className="pb-2" htmlFor={name}>
            {label}
          </label>
          <select
            className={`form-select ${error ? "is-invalid" : ""}`}
            id={name}
            name={name}
            value={formState[name]}
            onChange={handleChange}
            disabled={loadingSections || availableSections.length === 0}
          >
            <option value="">Select Section</option>
            {availableSections.map((section, index) => (
              <option key={index} value={section.value}>
                {section.label}
              </option>
            ))}
          </select>
          {loadingSections && <small>Loading sections...</small>}
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      );
    }

    switch (type) {
      case "email":
      case "text":
        return (
          <div className="form-group mb-2" key={name}>
            <label className="pb-2" htmlFor={name}>
              {label}
            </label>
            <input
              type={type}
              className={`form-control ${error ? "is-invalid" : ""}`}
              id={name}
              name={name}
              value={formState[name]}
              onChange={handleChange}
              placeholder={placeholder || ""}
              readOnly={readOnly}
              disabled={disabled}
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        );
      case "textarea":
        return (
          <div className="form-group mb-2" key={name}>
            <label className="pb-2" htmlFor={name}>
              {label}
            </label>
            <textarea
              className={`form-control ${error ? "is-invalid" : ""}`}
              id={name}
              name={name}
              value={formState[name]}
              onChange={handleChange}
              placeholder={placeholder || ""}
              readOnly={readOnly}
              disabled={disabled}
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        );
      case "select":
        return (
          <div className="form-group mb-2" key={name}>
            <label className="pb-2" htmlFor={name}>
              {label}
            </label>
            <select
              className={`form-select ${error ? "is-invalid" : ""}`}
              id={name}
              name={name}
              value={formState[name]}
              onChange={handleChange}
              disabled={disabled}
            >
              <option value="">Select {label}</option>
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        );
      case "file":
        return (
          <div className="form-group mb-2" key={name}>
            <label className="pb-2" htmlFor={name}>
              {label}
            </label>
            <input
              type="file"
              className={`form-control ${error ? "is-invalid" : ""}`}
              id={name}
              name={name}
              onChange={handleFileChange}
              disabled={disabled}
            />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid mt-3 h-100">
      <form onSubmit={onSubmit} className="d-flex flex-column h-100 gap-3">
        <div className="form-row row">
          {formConfig.map((field, index) => (
            <div className="col-md-6" key={index}>
              {renderField(field)}
            </div>
          ))}
        </div>
        <div className="control-buttons d-flex gap-2 justify-content-end mt-auto mb-4">
          {hasCancelBtn && (
            <Button
              label="Cancel"
              backgroundColor={"white"}
              onClick={onCancelClick}
            />
          )}
          {hasButtons && (
            <Button
              label={submitBtnText}
              backgroundColor={"#f6921e"}
              textColor={"white"}
              type="submit"
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default DynamicFormRenderer;
