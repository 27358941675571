import React, { useEffect, useRef, useState } from "react";
import { CrossLogo } from "../../../../assets/images/svg";
import Button from "../../../../components/Button";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  tagStudents,
  unTagStudents,
  deleteMedia,
} from "./studentListModalService";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./studentListModal.scss";
import SpotlightVivaItem from "../audioPlayer.js/AudioPlayer";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import Alert from "../../../../components/Alerts/Alert";
import {
  DeleteLogbook,
  DeleteMedia,
  RevertMedia,
  setActivityMedias,
  SetDeleteActivityMedia,
  SetStatus,
} from "../activityDetailsSlice";

const StudentsListModal = ({
  displayModal,
  setShowModal,
  mediaSelected,
  setMediaSelected,
  callActivity,
  setCallActivity,
  modalStatus,
  setModalStatus,
  viewerMediaSelected,
  mediaViewer,
  setMediaViewer,
  setLastMediaSelected, 
  currentViewerScope,
  setCurrentViewerScope,
  currentIndex,
  selectedStudentName,
  setSelectedStudentName
}) => {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMesage] = useState("");
  const [currentMedia, setCurrentMedia] = useState(currentViewerScope[currentIndex])
  const [selectedIndex, setSelectedIndex] = useState(currentIndex)
  const dispatch = useDispatch();
  const modalRef = useRef(null)
  const videoRefs = useRef([])
  const confirmationModalRef = useRef(null);

  const currentStatus = useSelector(
    (state) => state.activityDetails.currentStatus
  );

  const handleVideoPause = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (i !== index && video) {
        video.pause()
      }
    })
  }


  useEffect(() => {
    if (currentViewerScope.length > 0 && selectedIndex < currentViewerScope.length) {
      setCurrentMedia(currentViewerScope[selectedIndex]);
    } else {
      if(modalStatus == "tag" || modalStatus == "untag"){
        handleClose()
        setCurrentMedia(null);
      }
      setCurrentMedia(null);

    }
  }, [selectedIndex, currentViewerScope]);



  useEffect(() => {
    // Close modal when clicking outside of modal content
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && (!confirmationModalRef.current || !confirmationModalRef.current.contains(event.target))) {
        handleClose();
      }
    };

    if (displayModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displayModal, showConfirmationModal]);




  // Function to close modal
  const handleClose = () => {
    if (modalStatus == "tag" || modalStatus == "untag") {
      let temp_med = []
      temp_med.push(currentMedia)
      setLastMediaSelected(temp_med)
      callActivity ? setCallActivity(false) : setCallActivity(true);

    } else {
      let temp_med = []
      temp_med.push(currentMedia)
      setLastMediaSelected(temp_med)
      setMediaSelected([]);
      setCurrentViewerScope([])
    }
    setShowModal(false);
  };


  const swapImages = (revert) => {
    if (currentStatus == "Approve" && !revert){
      console.log("inside approved")
      dispatch(SetStatus("Delete"));
    }
    if (revert) {
      console.log("inside revert ")
      dispatch(
        RevertMedia({
          media: currentMedia,
          media_type: currentMedia.media_category,
          student: selectedStudentName,
        })
      );
    } else {
      console.log("inside not revert")
      dispatch(
        DeleteMedia({
          media: currentMedia,
          media_type: currentMedia.media_category,
          student: selectedStudentName,
        })
      );
    }

    setCurrentViewerScope((prevScope) => {
      const updatedScope = prevScope.filter((media) => media.media_id !== currentMedia.media_id);
  
      // Check if updatedScope is empty after deletion
      if (updatedScope.length === 0) {
        console.log("No media left");
        setLastMediaSelected([])
        setShowModal(false); // Close the modal if no items are left
      }
  
      return updatedScope;
    });

    setSelectedIndex((prevIndex) =>
      prevIndex >= currentViewerScope.length - 1 ? 0 : prevIndex
    );

    setCurrentMedia(currentViewerScope[selectedIndex])
    // handleClose()

  }


  const [studentIds, SetStudentIds] = useState([]);

  const studentList = useSelector((state) => state.activityDetails.studentList);

  const handleStudentChange = (selectedOptions) => {
    // Map selected options to an array of student IDs
    const selectedIds = selectedOptions.map((option) => option.value);
    SetStudentIds(selectedIds);
    // SetStudentIds(selectedOptions || [])
  };


  const tagSingleMedia = async() => {
    const media_ids = []
    media_ids.push(currentMedia.media_id)
    console.log(media_ids, studentIds)
    let response = await tagStudents(studentIds, media_ids);
    SetStudentIds([])

    setCurrentViewerScope((prevScope) => {
      const updatedScope = prevScope.filter((media) => media.media_id !== currentMedia.media_id);
  
      // Check if updatedScope is empty after deletion
      if (updatedScope.length === 0) {
        console.log("No media left");
        setLastMediaSelected([])
        setCallActivity(!callActivity)
        setShowModal(false); // Close the modal if no items are left
      }
  
      return updatedScope;
    });

    setSelectedIndex((prevIndex) =>
      prevIndex >= currentViewerScope.length - 1 ? 0 : prevIndex
    );
  }

  const unTagSingleMedia = async() => {
    const media_ids = []
    media_ids.push(currentMedia.media_id)
    const user_ids = []
    user_ids.push(currentMedia.user_id)
    console.log(media_ids, user_ids)
    let response = await unTagStudents(user_ids, media_ids);

    setCurrentViewerScope((prevScope) => {
      const updatedScope = prevScope.filter((media) => media.media_id !== currentMedia.media_id);
  
      // Check if updatedScope is empty after deletion
      if (updatedScope.length === 0) {
        console.log("No media left");
        setLastMediaSelected([])
        setCallActivity(!callActivity)
        setShowModal(false); // Close the modal if no items are left
      }
  
      return updatedScope;
    });

    setSelectedIndex((prevIndex) =>
      prevIndex >= currentViewerScope.length - 1 ? 0 : prevIndex
    );

  }



  const handleDeleteSingleMedia = async () => {
    console.log("inside untag selected media")
    const media_ids = []
    media_ids.push(currentMedia.media_id)
    console.log(media_ids, studentIds)
    let response = await deleteMedia(media_ids);
    setShowConfirmationModal(false);

    setCurrentViewerScope((prevScope) => {
      const updatedScope = prevScope.filter((media) => media.media_id !== currentMedia.media_id);
  
      // Check if updatedScope is empty after deletion
      if (updatedScope.length === 0) {
        console.log("No media left");
        setShowModal(false); // Close the modal if no items are left
        setCallActivity(!callActivity)
      }
  
      return updatedScope;
    });
  

    setSelectedIndex((prevIndex) =>
      prevIndex >= currentViewerScope.length - 1 ? 0 : prevIndex
    );

  }


  const tagSelectedMedia = async () => {
    const media_ids = mediaViewer.map((media) => media.media_id);
    let response = await tagStudents(studentIds, media_ids);
    setMediaViewer([]);
    setShowModal(false);
    callActivity ? setCallActivity(false) : setCallActivity(true);
  };

  const unTagSelectedMedia = async () => {
    const media_ids = mediaViewer.map((media) => media.media_id);
    const user_ids = mediaViewer.map((media) => media.user_id);
    let response = await unTagStudents(user_ids, media_ids);
    setMediaViewer([]);
    setShowModal(false);
    callActivity ? setCallActivity(false) : setCallActivity(true);
  };


  const handleDeleteMedia = async () => {
    console.log("inside delete media");
    const media_ids = mediaViewer.map((media) => media.media_id);
    let response = await deleteMedia(media_ids);
    setMediaViewer([]);
    setShowConfirmationModal(false);
    setShowModal(false);
    callActivity ? setCallActivity(false) : setCallActivity(true);
  };

  const onDeleteClick = () => {
    console.log("delete button clicked");
    console.log(modalStatus)
    setShowConfirmationModal(true);
  };

  const handleSingleStudent = (e) => {
    SetStudentIds([e.target.value]);

  };

  return (
    <div>
      {showConfirmationModal && (
        <ConfirmationModal
          ref={confirmationModalRef}
          title="Delete"
          message="Are you sure want delete selected media ?"
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          handleConfirmClick={(modalStatus == "tag" || modalStatus == "untag" || modalStatus == "delete") ? handleDeleteSingleMedia : handleDeleteMedia}
        />
      )}
      {modalStatus == "tag" ? (
        <div
          className={
            displayModal
              ? "modal show base-modal fade d-block"
              : "modal fade d-block"
          }
          style={{ backgroundColor: "rgba(0,0,0,.5)" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document" ref={modalRef}> 
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Tag Student</h5>
                <div className="close-modal" onClick={handleClose}>
                  <CrossLogo />
                </div>
              </div>
              <div className="modal-body reject-modal-body">
                {currentViewerScope.length > 0 && (
                  <Carousel 
                    showThumbs={false} 
                    selectedItem={selectedIndex}
                    onChange={(index) => {
                    setSelectedIndex(index)
                    setCurrentMedia(currentViewerScope[index])
                    handleVideoPause(index);
                    
                    }}>
                    {currentViewerScope.map((media, index) => (
                      <div key={index}>
                        {media.media_category === "photo" ||
                        media.media_category === "logsheet" ? (
                          <img
                            src={media.media_url}
                            height="400px"
                            width="400px"
                            alt={media.filename}
                            className="img-preview mb-2"
                          />
                        ) : media.media_category === "video" ? (
                          <video
                            ref = {(el) => videoRefs.current[index] = el}
                            className="mb-2"
                            height="400px"
                            width="400px"
                            src={media.media_url}
                            controls
                          />
                        ) : (
                          <SpotlightVivaItem
                            className="mb-2"
                            media_url={media.media_url}
                            filename={media.filename}
                            currentAudio={currentAudio}
                            setCurrentAudio={setCurrentAudio}
                          />
                        )}
                      </div>
                    ))}
                  </Carousel>
                )}

                {currentViewerScope[0].media_category === "viva" ||
                currentViewerScope[0].media_category === "logsheet" ? (
                  <select
                    className="form-select mb-2"
                    aria-label="Select student"
                    onChange={handleSingleStudent}
                    value={studentIds.length === 0 ? "" : studentIds[0]}
                  >
                    <option selected>Students</option>
                    {studentList.map((item, index) => (
                      <option key={index} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <Select
                    isMulti
                    name="students"
                    options={studentList}
                    className="basic-multi-select"
                    placeholder="Select Students"
                    classNamePrefix="select"
                    value={studentIds.map(id => ({ value: id, label: studentList.find(student => student.value === id)?.label }))}
                    onChange={handleStudentChange}
                  />
                )}
              </div>
              <div className="modal-footer">
                <Button
                  label="Delete Media"
                  backgroundColor="white"
                  textColor="#EA983E"
                  onClick={onDeleteClick}
                />
                <Button
                  label="Tag"
                  backgroundColor="#EA983E"
                  textColor="white"
                  onClick={tagSingleMedia}
                />
              </div>
            </div>
          </div>
        </div>
      ) : modalStatus == "checkBoxTag" ? (
      <div
        className={
          displayModal
            ? "modal show base-modal fade d-block"
            : "modal fade d-block"
        }
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        role="dialog"
      >
        <div className="modal-dialog" role="document" ref={modalRef}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Tag Student</h5>
              <div className="close-modal" onClick={handleClose}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              {mediaViewer.length > 0 && (
                <Carousel 
                  showThumbs={false} 
                  >
                  {mediaViewer.map((media, index) => (
                    <div key={index}>
                      {media.media_category === "photo" ||
                      media.media_category === "logsheet" ? (
                        <img
                          src={media.media_url}
                          height="400px"
                          width="400px"
                          alt={media.filename}
                          className="img-preview mb-2"
                        />
                      ) : media.media_category === "video" ? (
                        <video
                        ref = {(el) => videoRefs.current[index] = el}
                          className="mb-2"
                          height="400px"
                          width="400px"
                          src={media.media_url}
                          controls
                        />
                      ) : (
                        <SpotlightVivaItem
                          className="mb-2"
                          media_url={media.media_url}
                          filename={media.filename}
                          currentAudio={currentAudio}
                          setCurrentAudio={setCurrentAudio}
                        />
                      )}
                    </div>
                  ))}
                </Carousel>
              )}

              {mediaViewer[0].media_category === "viva" ||
              mediaViewer[0].media_category === "logsheet" ? (
                <select
                  className="form-select mb-2"
                  aria-label="Select student"
                  onChange={handleSingleStudent}
                >
                  <option selected>Students</option>
                  {studentList.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
              ) : (
                <Select
                  isMulti
                  name="students"
                  options={studentList}
                  className="basic-multi-select"
                  placeholder="Select Students"
                  classNamePrefix="select"
                  onChange={handleStudentChange}
                />
              )}
            </div>
            <div className="modal-footer">
              <Button
                label="Delete Media"
                backgroundColor="white"
                textColor="#EA983E"
                onClick={onDeleteClick}
              />
              <Button
                label="Tag"
                backgroundColor="#EA983E"
                textColor="white"
                onClick={tagSelectedMedia}
              />
            </div>
          </div>
        </div>
      </div>) : modalStatus == "untag" ? (
        <div
          className={
            displayModal ? "modal show fade d-block" : "modal fade d-block"
          }
          style={{ backgroundColor: "rgba(0,0,0,.5)" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document" ref={modalRef}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Untag Student</h5>
                <div className="close-modal" onClick={handleClose}>
                  <CrossLogo />
                </div>
              </div>
              <div className="modal-body reject-modal-body">
                {currentViewerScope.length > 0 && (
                  <Carousel showThumbs={false}>
                    {currentViewerScope.map((media, index) => (
                      <div key={index}>
                        {media.media_category === "photo" ||
                        media.media_category === "logsheet" ? (
                          <img
                            src={media.media_url}
                            height="400px"
                            width="400px"
                            alt={media.filename}
                            className="img-preview mb-2"
                          />
                        ) : media.media_category === "video" ? (
                          <video
                          ref = {(el) => videoRefs.current[index] = el}
                            className="mb-2"
                            height="400px"
                            width="400px"
                            src={media.media_url}
                            controls
                          />
                        ) : (
                          <SpotlightVivaItem
                            className="mb-2"
                            media_url={media.media_url}
                            filename={media.filename}
                          />
                        )}
                      </div>
                    ))}
                  </Carousel>
                )}
                <p className="img-preview">Are you sure you want to Untag ?</p>
              </div>
              <div className="modal-footer">
                <Button
                  label="Delete Media"
                  backgroundColor="white"
                  textColor="#EA983E"
                  onClick={onDeleteClick}
                />
                <Button
                  label="Untag"
                  backgroundColor="#EA983E"
                  textColor="white"
                  onClick={unTagSingleMedia}
                />
              </div>
            </div>
          </div>
        </div>
      ) : modalStatus == "checkBoxUnTag" ? (
        <div
          className={
            displayModal ? "modal show fade d-block" : "modal fade d-block"
          }
          style={{ backgroundColor: "rgba(0,0,0,.5)" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document" ref={modalRef}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Viewer</h5>
                <div className="close-modal" onClick={handleClose}>
                  <CrossLogo />
                </div>
              </div>
              <div className="modal-body reject-modal-body">
                {mediaViewer.length > 0 && (
                  <Carousel showThumbs={false}>
                    {mediaViewer.map((media, index) => (
                      <div key={index}>
                        {media.media_category === "photo" ||
                        media.media_category === "logsheet" ? (
                          <img
                            src={media.media_url}
                            height="400px"
                            width="400px"
                            alt={media.filename}
                            className="img-preview mb-2"
                          />
                        ) : media.media_category === "video" ? (
                          <video
                          ref = {(el) => videoRefs.current[index] = el}
                            className="mb-2"
                            height="400px"
                            width="400px"
                            src={media.media_url}
                            controls
                          />
                        ) : (
                          <SpotlightVivaItem
                            className="mb-2"
                            media_url={media.media_url}
                            filename={media.filename}
                          />
                        )}
                      </div>
                    ))}
                  </Carousel>
                  
                )}
                <div className="modal-footer">
                <Button
                  label="Delete Media"
                  backgroundColor="white"
                  textColor="#EA983E"
                  onClick={onDeleteClick}
                />
                <Button
                  label="Untag"
                  backgroundColor="#EA983E"
                  textColor="white"
                  onClick={unTagSelectedMedia}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      ) : (modalStatus == "delete" || modalStatus == "publish") ? (
        <div
        className={
          displayModal
            ? "modal show base-modal fade d-block"
            : "modal fade d-block"
        }
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        role="dialog"
      >
        <div className="modal-dialog" role="document" ref={modalRef}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{modalStatus == "delete" ? "Add to Delete" : "Add to Publish"}</h5>
              <div className="close-modal" onClick={handleClose}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              {currentViewerScope.length > 0 && (
                <Carousel 
                  showThumbs={false} 
                  selectedItem={selectedIndex}
                  onChange={(index) => {
                  setSelectedIndex(index)
                  setCurrentMedia(currentViewerScope[index])
                  
                  }}>
                  {currentViewerScope.map((media, index) => (
                    <div key={index}>
                      {media.media_category === "photo" ||
                      media.media_category === "logsheet" ? (
                        <img
                          src={media.media_url}
                          height="400px"
                          width="400px"
                          alt={media.filename}
                          className="img-preview mb-2"
                        />
                      ) : media.media_category === "video" ? (
                        <video
                          ref = {(el) => videoRefs.current[index] = el}
                          className="mb-2"
                          height="400px"
                          width="400px"
                          src={media.media_url}
                          controls
                        />
                      ) : (
                        <SpotlightVivaItem
                          className="mb-2"
                          media_url={media.media_url}
                          filename={media.filename}
                          currentAudio={currentAudio}
                          setCurrentAudio={setCurrentAudio}
                        />
                      )}
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="modal-footer">
              <Button
                label={modalStatus == "delete" ? "Add to Delete" : "Add to Publish"}
                backgroundColor="white"
                textColor="#EA983E"
                onClick={() => swapImages(false)}
              />
            </div>
          </div>
        </div>
      </div>
    ) : (<></>)
    }
    </div>
  );
};

export default StudentsListModal;
