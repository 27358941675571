import { createSlice } from "@reduxjs/toolkit";

const staffMasterSlice = createSlice({
  name: "staffMaster",
  initialState: {
    staffs: [],
    isStaffEditable: false,
    isNewEntry: false,
    isEditEntry: false,
  },
  reducers: {
    setStaffs: (state, action) => {
      state.staffs = action.payload;
    },
    setIsStaffEditable: (state, action) => {
      state.isStaffEditable = action.payload;
    },
    setIsNewEntry: (state, action) => {
      state.isNewEntry = action.payload;
    },
    setIsEditEntry: (state, action) => {
      state.isEditEntry = action.payload;
    },
  },
});

export const { setStaffs, setIsStaffEditable, setIsNewEntry, setIsEditEntry } =
  staffMasterSlice.actions;
export default staffMasterSlice.reducer;
