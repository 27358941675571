// Modal.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "./ModalSlice.js";
import "./modal.scss";

const Modal = ({ title, children, onCloseClick = () => {} }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modal); // Access modal state

  if (!isOpen) return null; // Don't render if modal is closed

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-header2">
          <h4>{title}</h4>
          <button
            onClick={() => {
              onCloseClick();
              dispatch(closeModal());
            }}
            className="close-button"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
