import axios from "axios"

import { DEV_BASE_URL } from "../../../utils/constants/routes"

export const uploadActivityMedia = async (file, activity_id, media_category, order) => {
    console.log(activity_id)
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.post(`${DEV_BASE_URL}/media/upload`,
        formData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            },
            params : {
                "activity_id" : activity_id,
                'media_category' : media_category,
                'order' : order
            }
        }
    )
    return response.data
}

export const uploadThumbnail = async (blob, media_id) => {
    const formData = new  FormData()
    const thumbnailFile = new File([blob], `thumbnail-${media_id}.jpg`, { type: 'image/jpeg' });
    formData.append('file', thumbnailFile);

    const response = await axios.post(`${DEV_BASE_URL}/api/v1/media/upload/thumbnail/${media_id}`,
        formData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data
}