import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const studentMasterDetails = async (page, page_size = 10) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getStudents`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      page,
      page_size,
    },
  });

  return response.data;
};

export const createStudentAPI = async (studentData) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/createStudents`,
      studentData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    return error;
  }
};

export const createBulkStudentAPI = async (studentData) => {

  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/onboard/students`,
      studentData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    return error;
  }
}

export const getSchoolSectionAPI = async (grade_id) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getSection`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      grade_id,
    },
  });

  return response.data.data;
};
