import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assessmentDetails : []
}

const assessmentDetailSlice = createSlice({
    name : "assessments",
    initialState,
    reducers : {
        setAssessments : (state, action) => {
            let data = action.payload;
            let temp_assessment = []
            data?.map((ele) => {
                temp_assessment.push(ele)
            })  
            state.assessmentDetails = temp_assessment
        }
    }
});

export const {setAssessments} = assessmentDetailSlice.actions;

export default assessmentDetailSlice.reducer;