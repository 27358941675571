import React, { useEffect, useState } from "react";
import { DeleteLogo, EditLogo } from "../../../../../assets/images/svg";
import Card from "../../../../../components/Card";
import Table from "../../../../../components/Table/Table";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";
import { useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal/Modal";
import {
  closeModal,
  openModal,
} from "../../../../../components/Modal/ModalSlice";
import { useParams } from "react-router-dom";
import {
  createSchoolSectionAPI,
  getSchoolGradeAPI,
  getSchoolSectionsAPI,
} from "../../schoolMasterService";

const Section = () => {
  const [sectionsData, setSectionsData] = useState([]);
  const [maxId, setMaxId] = useState(0);
  const [callApi, setCallApi] = useState(true);
  const schoolSectionStatusList = ["ALL", "Active", "Inactive"];

  const { id } = useParams();
  let schoolId = id;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSchoolSections = async () => {
      const response = await getSchoolSectionsAPI(schoolId);
      setSectionsData(response);
    };

    fetchSchoolSections();
  }, [schoolId, callApi]);

  useEffect(() => {
    let maxId = sectionsData.reduce((acc, curr) => {
      return curr.id > acc ? curr.id : acc;
    }, 0);
    setMaxId(maxId);
  }, [sectionsData]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "106px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
        >
          {row.name}
        </span>
      ),
      width: "306px",
    },
    {
      name: "Grade",
      selector: (row) => row.grade_name,
      sortable: true,
      width: "306px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.description}
        >
          {row.description}
        </span>
      ),
      width: "306px",
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            // dispatch(setSchoolEditable, true);
            // navigate(`${row.id}`);
          }}
        >
          <EditLogo />
        </button>
      ),
      width: "auto",
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            // dispatch(setSchoolEditable, true);
            // navigate(`${row.id}`);
          }}
        >
          <DeleteLogo />
        </button>
      ),
      width: "auto",
    },
  ];

  return (
    <div className="school__master__section--page">
      <Card>
        <div className="school__master__section--list p-3 card-lg">
          <Table
            entity="school_section_master"
            data={sectionsData}
            columns={columns}
            statusList={schoolSectionStatusList}
            buttonLabel="+ Section"
            onAddClick={() => dispatch(openModal("sectionModal"))}
            clickableRow={false}
          />
        </div>
      </Card>
      <SectionModal id={maxId + 1} callApi={callApi} setCallApi={setCallApi} />
    </div>
  );
};

const SectionModal = ({ id, callApi, setCallApi }) => {
  const [gradeOptions, setGradeOptions] = useState([]);
  const school_id = useParams().id;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchGradesFromAPI = async (school_id) => {
      const data = await getSchoolGradeAPI(school_id);
      let options = data.map((i) => ({
        value: i.id,
        label: i.name,
      }));
      setGradeOptions(options);
    };
    fetchGradesFromAPI(school_id);
  }, [school_id]);

  const config = [
    {
      name: "id",
      label: "Id",
      type: "text",
      value: id,
      disabled: true,
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    {
      name: "gradeToAdd",
      label: "Grade",
      type: "select",
      required: true,
      options: gradeOptions,
    },
    {
      name: "description",
      label: "Description",
      required: true,
      type: "textarea",
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
      required: true,
    },
  ];

  const handleFormSubmit = async (data) => {
    let d = {
      name: data.name,
      description: data.description,
      school_id: Number(school_id),
      status: data.status,
      grade_id: Number(data.gradeToAdd),
    };
    const response = await createSchoolSectionAPI(d);

    if (response.status) {
      alert(response.message);
      dispatch(closeModal());
      callApi ? setCallApi(false) : setCallApi(true);
    } else {
      alert("Something went wrong" + response.message);
      dispatch(closeModal());
      callApi ? setCallApi(false) : setCallApi(true);
    }
  };

  return (
    <Modal title="New Section">
      <div className="d-flex flex-column">
        <DynamicFormRenderer
          formConfig={config}
          hasCancelBtn={false}
          submitBtnText="Save"
          handleSubmit={handleFormSubmit}
        />
      </div>
    </Modal>
  );
};

export default Section;
