import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";
import { curriculumDetails } from "../../master/curriculum/curricumListService";

/**
 *
 * @param {string} token
 * @returns school master details. List of schools
 */
export const schoolMasterDetails = async (
  token = localStorage.getItem("token"),
  school_id = ""
) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getSchool`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      school_id,
    },
  });

  return response.data.data;
};

export const getCurriculumDropdown = async () => {
  const response = await curriculumDetails();
  let dropdownData = [];
  response.forEach((element) => {
    dropdownData.push({
      value: element.id,
      label: element.name,
    });
  });

  return dropdownData;
};

export const createSchoolAPI = async (schoolData) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/createSchool`,
    schoolData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updateSchoolAPI = async (schoolData) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/updateSchool`,
    schoolData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

/*************************************** GRADE ***********************************/

export const getSchoolGradeAPI = async (school_id) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getSchoolGrade`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      school_id,
    },
  });

  return response.data.data;
};

export const createSchoolGradeAPI = async (gradeData) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/createSchoolGrade`,
    gradeData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const updateSchoolGradeAPI = async (gradeData) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/updateSchoolGrade`,
    gradeData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

/*************************************** SECTION ************************************/

export const getSchoolSectionsAPI = async (school_id) => {
  try {
    const response = await axios.get(`${DEV_BASE_URL}/api/v1/getSection`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        school_id,
      },
    });

    if (response.status) {
      return response.data.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    alert(error.message);
    return [];
  }
};

export const createSchoolSectionAPI = async (sectionData) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/createSection`,
      sectionData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.data.status) {
      return response.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    alert(error.message);
    return {};
  }
};

/*************************************** ACTIVITY ************************************ */

export const getSchoolActivityAPI = async (school_id) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/api/v1/getSchoolActivity`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          school_id,
        },
      }
    );

    if (response.status) {
      return response.data.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    alert(error.message);
    return [];
  }
};

export const createSchoolActivityAPI = async (activityData) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/createSchoolActivity`,
      activityData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.data.status) {
      return response.data;
    } else {
      throw Error(response.data.message);
    }
  } catch (error) {
    alert(error.message);
    return {};
  }
};
