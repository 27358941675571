import { createSlice } from "@reduxjs/toolkit";

const studentMasterSlice = createSlice({
  name: "studentMaster",
  initialState: {
    students: [],
    isStudentEditable: false,
    isNewEntry: false,
    isEditEntry: false,
  },
  reducers: {
    setStudents: (state, action) => {
      state.students = action.payload;
    },
    setIsStudentEditable: (state, action) => {
      state.isStudentEditable = action.payload;
    },
    setIsNewEntry: (state, action) => {
      state.isNewEntry = action.payload;
    },
    setIsEditEntry: (state, action) => {
      state.isEditEntry = action.payload;
    },
  },
});

export const {
  setStudents,
  setIsStudentEditable,
  setIsNewEntry,
  setIsEditEntry,
} = studentMasterSlice.actions;
export default studentMasterSlice.reducer;
